module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"transparent","linkImagesToOriginal":false,"disableBgImageOnAlpha":true,"maxWidth":800,"showCaptions":false,"markdownCaptions":false,"quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/Users/samuelsson/Projects/personal/samuelsson-homepage/src/components/Layout.tsx"},"gatsbyRemarkPlugins":[{"resolve":"/Users/samuelsson/Projects/personal/samuelsson-homepage/node_modules/gatsby-remark-prismjs","id":"96900835-4b61-53a8-8a41-f46b0b717fb0","name":"gatsby-remark-prismjs","version":"6.5.0","modulePath":"/Users/samuelsson/Projects/personal/samuelsson-homepage/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/samuelsson/Projects/personal/samuelsson-homepage/node_modules/gatsby-remark-images","id":"79d037bc-8d76-5b02-8cf2-6e6dd42bdf07","name":"gatsby-remark-images","version":"6.5.1","modulePath":"/Users/samuelsson/Projects/personal/samuelsson-homepage/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"backgroundColor":"transparent","linkImagesToOriginal":false,"disableBgImageOnAlpha":true,"maxWidth":800},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/samuelsson/Projects/personal/samuelsson-homepage","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"transparent","linkImagesToOriginal":false,"disableBgImageOnAlpha":true,"maxWidth":800},
    },{
      plugin: require('../node_modules/gatsby-styled-components-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"light":{"body":{"backgroundColor":"#f9f9f9","color":"#222"},"blockquote":{"backgroundColor":"#f3f3f3","beforeColor":"#999","borderLeftColor":"#999","color":"#666","citeColor":"#222"},"prism":{"code":{"color":"#222","backgroundColor":"rgba(27, 31, 35, 0.08)"},"codeBlock":{"color":"#d3e1f0","backgroundColor":"#23232d","boxShadow":"0 2px 5px rgba(0, 0, 0, 0.25)"}},"Footer":{"backgroundColor":"#f3f3f3","borderTopColor":"#eee"},"Navigation":{"a":{"color":"#666","hoverColor":"#000000","activeColor":"#000000"}},"PostHeader":{"StyledTag":{"color":"#666","backgroundColor":"#eee","boxShadow":"0 2px 3px #ddd","hoverColor":"#ffffff","hoverBackgroundColor":"#5a86cb","hoverBoxShadow":"0 2px 5px #999"}},"PostList":{"StyledLink":{"borderBottom":"1px solid #e6e6e6","hoverBackgroundColor":"#f3f3f3"}},"PostMeta":{"color":"#666","hoverColor":"#000000"}},"dark":{"body":{"backgroundColor":"#1d232d","color":"#a6adbb"},"blockquote":{"backgroundColor":"#171a24","beforeColor":"#c6cddc","borderLeftColor":"#12151c","color":"#c6cddc","citeColor":"#878e9a"},"prism":{"code":{"color":"#c6cddc","backgroundColor":"#12151c"},"codeBlock":{"color":"#a6adbb","backgroundColor":"#12151c","boxShadow":"none"}},"Footer":{"backgroundColor":"#12151c","borderTopColor":"#0a0a10"},"Navigation":{"a":{"color":"#878e9a","hoverColor":"#c6cddc","activeColor":"#c6cddc"}},"PostHeader":{"StyledTag":{"color":"#878e9a","backgroundColor":"#12151c","boxShadow":"none","hoverColor":"#ffffff","hoverBackgroundColor":"#5a86cb","hoverBoxShadow":"none"}},"PostList":{"StyledLink":{"borderBottom":"1px solid #12151c","hoverBackgroundColor":"#171a24"}},"PostMeta":{"color":"#878e9a","hoverColor":"#c6cddc"}}},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    }]
